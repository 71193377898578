import React, { useState } from 'react';
import * as styles from './ProductCardGrid.module.css';

import Drawer from '../Drawer';
import ProductCard from '../ProductCard';
import QuickView from '../QuickView';
import Slider from '../Slider';
import { constructProductMeta } from '../../helpers/data/products';

/**
 *
 * @param {{
 * data: import("../../types/global").ProductType[],
 * height: number,
 * columns: number,
 * spacing: number,
 * showSlider:boolean
 * }} props
 * @returns
 */
const ProductCardGrid = (props) => {
  const [showQuickView, setShowQuickView] = useState(false);
  const [product, setProduct] = useState(null);
  const { height, columns = 3, data, spacing, showSlider = false } = props;
  const columnCount = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };
  const onShowQuickView =
    (product = null) =>
    () => {
      setShowQuickView(!showQuickView);
      setProduct(product);
    };

  const renderCards = () => {
    return data.map((product, index) => {
      const productImage = product.images?.[0];
      const productMeta = constructProductMeta(product.attributes);
      return (
        <ProductCard
          key={index}
          height={productImage?.height || height}
          price={product.price || product.regular_price}
          imageAlt={product.name}
          name={product.name}
          image={productImage?.src}
          meta={productMeta}
          originalPrice={product.price}
          link={`/product/${product.slug}/`}
          showQuickView={onShowQuickView(product)}
        />
      );
    });
  };

  return (
    <div className={styles.root} style={columnCount}>
      <div
        className={`${styles.cardGrid} ${
          showSlider === false ? styles.show : ''
        }`}
        style={columnCount}
      >
        {data && renderCards()}
      </div>

      {showSlider === true && (
        <div className={styles.mobileSlider}>
          <Slider spacing={spacing}>{data && renderCards()}</Slider>
        </div>
      )}

      <Drawer visible={showQuickView} close={onShowQuickView(null)}>
        <QuickView close={onShowQuickView(null)} data={product} />
      </Drawer>
    </div>
  );
};

export default ProductCardGrid;
