// extracted by mini-css-extract-plugin
export var contentContainer = "QuickView-module--contentContainer--XtQPu";
export var fieldError = "QuickView-module--fieldError--3TwtJ";
export var price = "QuickView-module--price--bRQF3";
export var productContainer = "QuickView-module--productContainer--6JnFX";
export var productImageContainer = "QuickView-module--productImageContainer--GGZzd";
export var productName = "QuickView-module--productName--2KrOv";
export var productShortDescription = "QuickView-module--productShortDescription--630Ip";
export var root = "QuickView-module--root--QRCZM";
export var sectionContainer = "QuickView-module--sectionContainer--qFFc2";
export var titleContainer = "QuickView-module--titleContainer--l8zIZ";