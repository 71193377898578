// extracted by mini-css-extract-plugin
export var bagContainer = "ProductCard-module--bagContainer--OSVL1";
export var detailsContainer = "ProductCard-module--detailsContainer--j5O9z";
export var hide = "ProductCard-module--hide--aQxe0";
export var imageContainer = "ProductCard-module--imageContainer--AJJJN";
export var image_bg = "ProductCard-module--image_bg--CFtO1";
export var meta = "ProductCard-module--meta--B2Q5Z";
export var originalPrice = "ProductCard-module--originalPrice--QlHFx";
export var prices = "ProductCard-module--prices--zUNeH";
export var productName = "ProductCard-module--productName--5sF7I";
export var root = "ProductCard-module--root--1-zNb";
export var salePrice = "ProductCard-module--salePrice--MIaoB";
export var show = "ProductCard-module--show--buZwy";