import React from "react";
// @ts-ignore
import Button from "../Button";
import PaginationPropsTypes from "./types";
// @ts-ignore
import * as styles from "./pagination.module.css";

export default function Pagination({ numOfPages, currentPageIndex, mainPath }: PaginationPropsTypes) {
  return (
    <nav aria-label="Page pagination nav">
      <ul className={`${styles.root} pagination justify-content-center justify-content-lg-end`}>
        <li className="page-item mx-1">
          <Button
            href={`${mainPath}/`}
            className={`page-link ${styles.paginationLink}`}
            level='terniary'
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </Button>
        </li>
        {
          Array.from({ length: numOfPages }).map((_, index) => {
            let isActive = currentPageIndex === index ? true : false;
            return (
              <li
                key={`page-shop-num-${_}-${index}`}
                className={`page-item mx-1`}
              >
                <Button
                  href={`${mainPath}/${index === 0 ? "" : index + 1 + "/"}`}
                  className={`page-link ${styles.paginationLink}`}
                  level={isActive ? 'primary' : 'secondary'}
                >
                  {index + 1}
                </Button>
              </li>
            );
          })
        }
        <li className="page-item ms-1">
        <Button
            href={`${mainPath}/${numOfPages == 1 ? "" : numOfPages + "/"}`}
            className={`page-link ${styles.paginationLink}`}
            level='terniary'
            aria-label="Previous"
          >
            <span aria-hidden="true">»</span>
          </Button>
        </li>
      </ul>
    </nav>
  );
}