import { ProductType } from "../../../types/global";

/**
 * Construct a meta data from product attributes
 * 
 * @param attributes product attributes
 * @returns something like +2 colors, +2 sizes...
 */
export function constructProductMeta(attributes: ProductType["attributes"]) {
  let meta = "";

  attributes?.forEach((attribute, index) => {
    if (attribute.visible === true && attribute.options.length > 0) {
      meta += index > 0 ? ", " : "";
      meta += `+ ${attribute.options.length} ${attribute.name}`;
    }
  });

  return meta;
}