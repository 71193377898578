import React, { useContext, useReducer, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../Button';
import CurrencyFormatter from '../CurrencyFormatter';
import SizeList from '../SizeList';

import AddItemNotificationContext from '../../context/AddItemNotificationProvider';

import * as styles from './QuickView.module.css';
import FormFieldError from '../form-field-error';
import { globalReducer } from '../../helpers/reducers';
import { objectifyFormData } from '../../helpers/form';
import { constructCartDataByProduct } from '../../helpers/cart';
import { validateOrderLineItem } from '../../helpers/validator/cart';
import { scrollIntoView } from '../../helpers/ui';
import httpRequest from '../../helpers/http';
import CartStorage from '../../helpers/storage';
import Alert from '../alert';

const initState = {
  loading: false,
  data: null,
  errors: null,
  message: null,
};
const QuickView = (props) => {
  const alertRef = useRef(null);
  const { close, buttonTitle = 'Ajouter au panier', data } = props;
  const [state, dispatch] = useReducer(globalReducer, initState);
  const ctxAddItemNotification = useContext(AddItemNotificationContext);
  const showNotification = ctxAddItemNotification.showNotification;
  const { storeDetails } = useStaticQuery(graphql`
    query QuickView {
      storeDetails: zzStoreWebsiteDetails {
        product_stock_url
      }
    }
  `);
  // close alert
  const onCloseAlert = () => {
    alertRef.current.classList.add("fade-down");
    const timerId = setTimeout(() => {
      dispatch({
        type: "SUCCESS_WITH_ERRORS",
        payload: {
          errors: null,
          message: null
        }
      });
      clearTimeout(timerId);
    }, 600);
  };
  // add product to bag
  const onSubmit = async (event) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });
    const form = event.currentTarget;
    const formData = new FormData(form);
    const orderData = objectifyFormData(formData);
    const lineItem = constructCartDataByProduct(orderData);
    const errors = validateOrderLineItem(lineItem, data?.attributes);

    if (errors !== false) {
      await dispatch({
        type: "ERROR",
        payload: errors
      });
      // scroll to the element with error message
      if (!errors?.global) {
        const errorElement = document.querySelector('.js-invalid-feedback');
        scrollIntoView(errorElement, -150);
      }
      return false;
    }
    // verify product stock availability
    const response = await httpRequest({
      url: `${storeDetails.product_stock_url}/${orderData.product_id}/availability?quantity=${orderData.quantity}`,
      requestConfig: {
        method: "GET",
      }
    });

    if (response?.code !== "success") {
      await dispatch({
        type: "ERROR",
        payload: response.errors
      });
      // scroll to field error
      if (!response?.errors?.global) {
        const errorElement = document.querySelector('.js-invalid-feedback');
        scrollIntoView(errorElement, -150);
      }
      return;
    } else {
      const cart = new CartStorage();
      const productDetails = {
        id: String(data?.wordpress_id),
        slug: data?.slug,
        name: data?.name,
        regular_price: data?.price || data?.regular_price,
        thumbnail: data?.images?.[0],
      }
      await Promise.all([
        cart.addItem(lineItem),
        cart.pushItemDetails(productDetails)
      ]);
      dispatch({
        type: 'RESET_STATE',
        payload: initState
      })
      close();
      showNotification({
        ...data,
        quantity: orderData.quantity,
        options: lineItem.meta_data
      });
    }
  }

  if (!!data === false) return null;

  return (
    <form className={styles.root} onSubmit={onSubmit}>
      <Alert
        title={'Ajouter au panier'}
        message={state?.errors?.global}
        variant={'danger'}
        onClose={onCloseAlert}
        reference={alertRef}
        className='alert'
      />
      <input name="product_id" value={data?.wordpress_id} type="hidden" />
      <input name="quantity" value={1} type="hidden" />
      <div className={styles.titleContainer}>
        <h4>{data.name}</h4>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.productContainer}>
          <div className={styles.price}>
            <CurrencyFormatter currency={'DZD'} amount={data.regular_price} />
          </div>
          <div className={styles.productImageContainer}>
            <img alt={data.name} src={data.images?.[0].src} loading={'lazy'} />
          </div>
          <p className={styles.productShortDescription}>
            {data?.short_description}
          </p>
        </div>

        {data.attributes?.map((attribute) => {
          if (attribute.visible === false) return null;
          return (
            <div
              className={styles.sectionContainer}
              key={`quick-view-attribute-${data.id}-${attribute.name}`}
            >
              <SizeList label={attribute.name} sizeList={attribute.options} id='quick-view' />
            </div>
          );
        })}
        <FormFieldError
          message={state.errors?.options}
          className={`${styles.fieldError} js-invalid-feedback`}
        />

        <Button
          type='submit'
          level={'primary'}
          disabled={state?.loading}
          fullWidth
        >
          {buttonTitle}
        </Button>
      </div>
    </form>
  );
};

export default QuickView;
