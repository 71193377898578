import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import * as styles from './ProductCard.module.css';

import Icon from '../Icons/Icon';
import CurrencyFormatter from '../CurrencyFormatter';
import { getImageLink } from '../../helpers/images';

const ProductCard = (props) => {
  const {
    image,
    imageAlt,
    name,
    price,
    originalPrice,
    meta,
    showQuickView,
    height = 580,
    link,
  } = props;
  const fallback = getImageLink(image, { formats: 'webp', query: '&q=1&blur=100' });
  const productImage = {
    height,
    width: 300,
    backgroundColor: '#B59F66aa',
    placeholder: {
      loading: 'lazy',
      fallback,
      sources: [
        {
          media: '',
          type: 'image/webp',
          srcSet: fallback,
        },
      ],
    },
    layout: 'fullWidth',
    images: {
      fallback: {
        src: image,
      },
      sources: [
        {
          media: '',
          type: 'image/webp',
          srcSet: getImageLink(image),
        },
      ],
      shouldLoad: false
    },
  };

  const handleRouteToProduct = () => {
    navigate(link);
  };

  const handleQuickView = (e) => {
    e.stopPropagation();
    showQuickView();
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        onClick={handleRouteToProduct}
        role={'presentation'}
      >
        <GatsbyImage
          image={productImage}
          alt={imageAlt}
          className={styles.image_bg}
          loading='lazy'
        />
        <div
          className={styles.bagContainer}
          role={'presentation'}
          onClick={handleQuickView}
        >
          <Icon symbol={'bagPlus'} />
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <Link to={link} className={styles.productName}>
          {name}
        </Link>
        <div className={styles.prices}>
          <span
            className={`${originalPrice !== undefined ? styles.salePrice : ''}`}
          >
            <CurrencyFormatter
              currency={'DZD'}
              amount={price}
            ></CurrencyFormatter>
          </span>
          {originalPrice && originalPrice > price && (
            <span className={styles.originalPrice}>
              <CurrencyFormatter
                currency={'DZD'}
                amount={originalPrice}
              ></CurrencyFormatter>
            </span>
          )}
        </div>
        <span className={styles.meta}>{meta}</span>
      </div>
    </div>
  );
};

export default ProductCard;
