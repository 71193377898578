import { graphql } from 'gatsby';
import React from 'react';

import Banner from '../../components/Banner';
import Pagination from '../../components/pagination';
import Container from '../../components/Container';
import Layout from '../../components/Layout';
import ProductCardGrid from '../../components/ProductCardGrid';
import Seo from '../../components/seo';

import * as styles from './shop.module.css';

const ShopPage = ({ pageContext, data: { productsList: { nodes: products } } }) => {
  const { numOfPages, currentPageIndex } = pageContext;

  return (
    <Layout>
      <div className={styles.root}>
        <Seo
          title={"Boutique"}
          description={products?.[0]?.name || ''}
          banner={products?.[0]?.images?.[0]?.src || ''}
          pathname={`/shop/`}
        />
        <Banner
          maxWidth={'650px'}
          name={`Boutique`}
          subtitle={''}
        />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.productContainer}>
            <ProductCardGrid data={products} />
          </div>
          <div className={styles.loadMoreContainer}>
            <span>{currentPageIndex + 1} de {numOfPages}</span>
            <Pagination
              numOfPages={numOfPages}
              currentPageIndex={currentPageIndex || 0}
              mainPath={`/shop`}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};


export const pageQuery = graphql`
  query ($limit: Int!, $skip: Int!) {
    buildMetadata: siteBuildMetadata {
      buildTime(formatString: "YYYY-MM-DD")
    }
    productsList: allWcProducts(
      limit: $limit,
			skip: $skip,
      filter: {
        wordpress_id: {
          nin: [0, -1]
        }
      }
    ) {
      totalCount
      nodes {
        wordpress_id
        slug
        name
        regular_price
        price
        description
        short_description
        category_ids
        total_sales
        attributes {
          id
          name
          options
          visible
        }
        images {
          src
          width
          height
          is_resized
        }
      }
    }
    productCategories: allWcProductsCategories(filter: {count: {gt: 0}}) {
      edges {
        node {
          term_id
          slug
          name
        }
      }
    }
  }
`;

export default ShopPage;
